/*
* Mainly scss modules, only imported to `assets/css/main.scss`
*/

/* ---------- scss placeholder --------- */

%heading {
  color: var(--heading-color);
  font-weight: 400;
  font-family: 'Lato', 'Microsoft Yahei', sans-serif;
}

%section {
  #core-wrapper & {
    margin-top: 2.5rem;
    margin-bottom: 2rem;
  }
}

%anchor {
  > a {
    font-size: 1rem;
    margin-left: 0.5rem;
  }

  @media (hover: hover) {
    > a {
      border-bottom: none !important;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.25s ease-in, visibility 0s ease-in 0.25s;
    }

    &:hover {
      > a {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.25s ease-in, visibility 0s ease-in 0s;
      }
    }
  }
}

%anchor-relative {
  @extend %anchor;

  > a {
    position: relative;
    bottom: 1px;
  }
}

%tag-hover {
  background: var(--tag-hover);
  transition: background 0.35s ease-in-out;
}

%table-cell {
  padding: 0.4rem 1rem;
  font-size: 95%;
  white-space: nowrap;
}

%link-hover {
  color: #d2603a !important;
  border-bottom: 1px solid #d2603a;
  text-decoration: none;
}

%link-color {
  color: var(--link-color);
}

%link-underline {
  border-bottom: 1px solid var(--link-underline-color);
}

%clickable-transition {
  transition: color 0.35s ease-in-out;
}

%no-cursor {
  user-select: none;
}

%no-bottom-border {
  border-bottom: none;
}

%cursor-pointer {
  cursor: pointer;
}

%normal-font-style {
  font-style: normal;
}

/* ---------- scss mixin --------- */

@mixin no-text-decoration {
  text-decoration: none;
}

%sidebar-links {
  color: rgba(117, 117, 117, 0.9);
  user-select: none;
}

@mixin ml-mr($value) {
  margin-left: $value;
  margin-right: $value;
}

@mixin pl-pr($val) {
  padding-left: $val;
  padding-right: $val;
}

@mixin input-placeholder {
  opacity: 0.6;
}

@mixin label($font-size: 1rem, $font-weight: 600, $color: var(--label-color)) {
  color: $color;
  font-size: $font-size;
  font-weight: $font-weight;
}

@mixin align-center {
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
